import { useState } from "react";
import { mcn } from "@/utils";
import Image from "next/image";
import IconAddImage from "./Icons/IconAddImage";

export type PersonaCoinProps = {
  className?: string;
  imageWrapperClassName?: string;
  displayName: string | undefined | null;
  imageUrl?: string | undefined | null;
  size?: "32" | "40" | "56" | "120";
  changePersonaImage?: boolean;
};

export const PersonaCoin = (props: PersonaCoinProps) => {
  const [imageLoadingComplete, setImageLoadingComplete] = useState(false);
  const { imageUrl,changePersonaImage=false, size = "56" } = props;
  const nameSplit = props.displayName?.split(" ");

  let initials;
  if (nameSplit?.length === 1) {
    initials = nameSplit[0][0];
  } else if (nameSplit && nameSplit.length >= 2) {
    initials = nameSplit[0][0] + nameSplit[nameSplit.length - 1][0];
  }

  return (
    <div
      className={mcn(
        "relative font-bold",
        size === "32" && "w-[32px] min-w-[32px] h-[32px] text-[16px]",
        size === "40" && "w-[40px] min-w-[40px] h-[40px] text-style-headline5",
        size === "56" && "w-[56px] min-w-[56px] h-[56px] text-[28px]",
        size === "120" && "w-[120px] min-w-[120px] h-[120px] text-[58px]",
        props.className
      )}
    >
      {!imageLoadingComplete && (
        <div
          className={mcn(
            "flex items-center justify-center",
            "w-full h-full rounded-full",
            "bg-light-blue",
            "shadow-inner",
            props.imageWrapperClassName
          )}
        >
          <span
            className={mcn(
              "text-light-yellow leading-none",
              size === "32" && "mt-3xs",
              size === "56" && "mt-2xs"
            )}
          >
            {initials ?? " "}
          </span>
        </div>
      )}
      {imageUrl && (
        <div
          className={mcn(
            "absolute top-0 w-full h-full",
            !imageLoadingComplete && "opacity-0",
            props.imageWrapperClassName
          )}
        >
          <Image
            className="rounded-full"
            layout="fill"
            src={imageUrl}
            alt="Profile photo"
            onLoadingComplete={() => setImageLoadingComplete(true)}
            loader={(resolverProps) => resolverProps.src}
          />
        </div>
      )}
      {changePersonaImage && (
        <IconAddImage
        className={
          "h-10 w-10 absolute bottom-0 right-0"
        }
        aria-hidden/>
      )}
    </div>
  );
};
