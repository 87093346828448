import {
  BrowseMenuLinkItem,
  HeadlessUiMenuLinkButton,
} from "@/components/Header/BrowseMenu";
import { mcn } from "@/utils/mergeClassNames";
import { buildUrlWithQuery } from "@/utils/url";
import { BrowseQueryParams } from "../../pages/browse";
import { LearningOpportunityType } from "@/server/backend-api/generated";

export const BrowseMenuLearningOpportunities = () => {
  const loTypeClassname = "border border-light-yellow";
  const challengesUrl = buildUrlWithQuery<BrowseQueryParams>("/browse", {
    learningOppTypes: LearningOpportunityType.Challenge.toString(),
  });
  const microModulesUrl = buildUrlWithQuery<BrowseQueryParams>("/browse", {
    learningOppTypes: LearningOpportunityType.MicroModule.toString(),
  });
  return (
    <>
      <HeadlessUiMenuLinkButton
        className="col-span-full"
        kind="secondary"
        href="/browse"
      >
        Browse all
      </HeadlessUiMenuLinkButton>

      <BrowseMenuLinkItem href={challengesUrl} className={loTypeClassname}>
        Challenges
      </BrowseMenuLinkItem>

      <BrowseMenuLinkItem
        href={microModulesUrl}
        className={mcn("rounded-l-full", loTypeClassname)}
      >
        Micro-modules
      </BrowseMenuLinkItem>
    </>
  );
};
