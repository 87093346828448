import { createBackendApiClient } from "./createBackendApiClient";
import { Liability as APILiability } from "./generated";

export type LiabilityTypeName = "CreateAccount";

export interface Liability {
  url: string | null;
  name: string | null;
}

function mapLiability(apiLiability: APILiability) {
  return {
    ...apiLiability,
    url: apiLiability.contentUrl,
  };
}

export const getLiability = async (
  liabilityTypeName: LiabilityTypeName
): Promise<Liability | null> => {
  const client = createBackendApiClient();
  switch (liabilityTypeName) {
    case "CreateAccount":
      return mapLiability(await client.account_GetCreateAccountLiability());
    default:
      throw new Error("No such liabilityTypeName");
  }
};
