import { PersonaCoin, PersonaCoinProps } from "@/components/PersonaCoin";
import Link from "next/link";
import { mcn } from "@/utils";
import { useRouter } from "next/router";
import { TooltipContainer, TooltipContentWrapper } from "@/components/Tooltip";

interface PersonaCoinLinkProps extends PersonaCoinProps {
  href: string;
  tooltipText?: string;
  
}

export function PersonaCoinLink(props: PersonaCoinLinkProps) {
  const { href, tooltipText, imageWrapperClassName, ...rest } = props;
  const router = useRouter();
  const isBrowserStandingOnLinkedPage = router.asPath === props.href;

  const tooltipOptions = {
    popperOptions: {
      modifiers: [
        {
          name: "offset",
          options: {
            offset: [0, 3],
          },
        },
      ],
    },
  };

  return (
    <>
      <Link
        href={href}
        aria-label={tooltipText ?? "Link to my profile" }
        className="group outline-none"
      >
        <TooltipContainer
          options={tooltipOptions}
          tooltip={
            <TooltipContentWrapper>{tooltipText ?? "Open your profile"}</TooltipContentWrapper>
          }
        >
          <PersonaCoin
            {...rest}
            imageWrapperClassName={mcn(
              imageWrapperClassName,
              isBrowserStandingOnLinkedPage && "ring-2 ring-yellow-100",
              "rounded-full",
              "group-hover:ring-8 group-hover:ring-white/10",
              "group-focus-visible:ring-4 group-focus-visible:ring-yellow-100"
            )}
          />
        </TooltipContainer>
      </Link>
    </>
  );
}
