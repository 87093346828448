import { useState } from "react";
import config from "../../config";
import Button, { ButtonProps } from "../Button";
import { useUser } from "../UserContext";
import { Dialog } from "./Dialog";

type Props = {
  buttonProps: ButtonProps;
};

/**
 * Renders as regular Button if user is authenticated, if not the buttons onClick opens a dialog with Sign in and Create account button.
 */
export function ButtonWithSigninDialogFallback(props: Props) {
  const [showDialog, setShowDialog] = useState(false);
  const { isAuthenticated } = useUser();
  const onClose = () => setShowDialog(false);

  const { buttonProps } = props;

  if (isAuthenticated) {
    return <Button {...buttonProps} />;
  }

  const { children, className, disabled, kind, label, size } = buttonProps;
  const buttonPropsToForward = {
    children,
    className,
    disabled,
    kind,
    label,
    size
  };

  return (
    <>
      <Button onClick={() => setShowDialog(true)} {...buttonPropsToForward} />

      <Dialog title="Sign in" open={showDialog} onClose={onClose}>
        <div className="flex justify-end gap-md mt-md flex-col max-w-sm mx-auto">
          <Button
            href={
              props.buttonProps.href
                ? `/signin?loginRedirect=${props.buttonProps.href}`
                : '/signin'
            }
            className="whitespace-nowrap"
            kind="primary"
          >
            Sign in
          </Button>
          <span className="text-center text-style-button text-grey-160">
            or
          </span>
          <Button
            href={config.auth.signUpUri}
            className="whitespace-nowrap"
            kind="secondary"
          >
            Create account
          </Button>
        </div>
      </Dialog>
    </>
  );
}
