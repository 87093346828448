import { SVGProps } from "react";

const IconX = (props: SVGProps<SVGSVGElement>) => (
  <svg 
    width="1em" 
    height="1em" 
    viewBox="0 0 512 512" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    >
      <path 
        d="M256 0c141.384 0 256 114.616 256 256 0 141.384-114.616 256-256 256C114.616 512 0 397.384 0 256 0 114.616 114.616 0 256 0zm62.64 157.549h33.401l-72.974 83.407 85.85 113.495h-67.222l-52.645-68.837-60.244 68.837h-33.422l78.051-89.212-82.352-107.69h68.924l47.59 62.916 55.043-62.916zm-11.724 176.908h18.509L205.95 176.494h-19.861l120.827 157.963z" 
        fill="#002337"
        fillRule="evenodd"
        clipRule="evenodd"
      />
  </svg>


);

export default IconX;