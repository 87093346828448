import { mcn } from "@/utils/mergeClassNames";
import {
  ChangeEventHandler,
  DetailedHTMLProps,
  InputHTMLAttributes,
  RefObject,
  useEffect,
  useState,
  ReactNode,
} from "react";
import Label, { LabelProps } from "../Label";

export type CheckboxValue = string | ReadonlyArray<string> | number | undefined;

export type CheckboxProps = DetailedHTMLProps<
  InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  id: string;
  value?: CheckboxValue;
  labelProps?: LabelProps;
  innerRef?: RefObject<HTMLInputElement>;
  isBadge?: boolean;
  children?: ReactNode;
  onRenderIndicator?: () => ReactNode;
};

const Checkbox = ({
  labelProps,
  innerRef,
  isBadge,
  onRenderIndicator,
  children,
  checked,
  onChange,
  ...rest
}: CheckboxProps) => {
  const isControlled = checked;

  const [internalChecked, setInternalChecked] = useState<boolean>(!!checked);

  useEffect(() => {
    setInternalChecked(!!checked);
  }, [checked]);

  const internalOnChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    if (onChange) {
      onChange(event);
    }

    if (!isControlled) {
      setInternalChecked(!!event.target.checked);
    }
  };

  return (
    <div
      data-testid={`checkbox-${rest.id}`}
      className={mcn(
        rest.className,
        "relative flex flex-row items-start cursor-pointer",
        isBadge &&
          mcn(
            "inline-flex border-grey-40 border-solid border-[1px] p-xs rounded-3",
            internalChecked ? "bg-light-yellow" : "bg-white"
          )
      )}
    >
      <input
        {...rest}
        onChange={onChange ?? internalOnChange}
        checked={internalChecked}
        ref={innerRef}
        type="checkbox"
        className={mcn(    
        "appearance-none",
        "cursor-pointer",
        "h-5 w-5",
        "mt-3xs",
        "flex-shrink-0",
        "input-default",
        "hover:input-hover",
        "disabled:input-disabled",
        "disabled:cursor-default",
        "focus-within:input-focus",
        "focus-within:input-focus",
        /* Base hovered */
        "hover:bg-light-yellow",
        "hover:checked:bg-yellow-110",
        "hover:disabled:input-disabled",
        /* Indicator */
        "after:content['']",
        "after:absolute",
        "after:opacity-0",
        isBadge
          ? "after:left-[16px] after:top-[14px] after:w-[5px] after:h-[9px]" // position
          : "after:left-[8px] after:top-[6px] after:w-[5px] after:h-[9px]", // position
        "after:border-b-[1px] after:border-r-[1px] after:rotate-45 after:border-grey-160", // checkmark
        /* Indicator indeterminate (dash) */
        "indeterminate:after:border-b-0",
        "indeterminate:after:rotate-90",
        "indeterminate:after:opacity-100",
        /* Indicator disabled */
        "disabled:checked:after:opacity-100",
        /* Indicator checked */
        !onRenderIndicator && "checked:after:opacity-100",
        "checked:bg-yellow-100",
        /* Indicator (all) hover */
        !onRenderIndicator && "hover:after:opacity-100",
        "hover:after:border-grey-120",
        "hover:checked:after:border-grey-160",
        "hover:disabled:after:opacity-0",
        "hover:disabled:checked:after:opacity-100",
        "hover:indeterminate:bg-yellow-100",
        /* Animations */
        "after:transition-opacity ease-linear after:duration-100",
        "peer")}
      />
      {/* Custom checkmark */}
      
      <Label
        {...labelProps}
        htmlFor={rest.id}
        className={mcn(
          labelProps?.className,
          "ml-2 text-style-body2 cursor-pointer peer-disabled:cursor-default"
        )}
      >
        {children}
      </Label>
    </div>
  );
};

export default Checkbox;
