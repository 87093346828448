import { SignInButton, SignUpButton } from "../AuthButtons";
import { Header } from "../Header/Header";
import { ButtonWithSigninDialogFallback } from "../Modal/ButtonWithSigninDialogFallback";
import {
  BrowseMenuContentDesktop,
  BrowseMenuContentMobile,
} from "./BrowseMenuContent";
import { useProfile } from "./useProfile";
import { UserMenuContentDesktop } from "./UserMenuContent";
import { PersonaCoinLink } from "@/components/PersonaCoinLink";

type Props = {
  transparentHeader?: boolean;
};

export const HeaderMain = (props: Props) => {
  const { isAuthenticated, profile } = useProfile();

  return (
    <Header
      transparentHeader={props.transparentHeader || isAuthenticated}
      sidebarDesktop={
        !isAuthenticated ? undefined : (
          <UserMenuContentDesktop profile={profile} />
        )
      }
      browseMenuContent={{
        mobile: <BrowseMenuContentMobile profile={profile} />,
        desktop: <BrowseMenuContentDesktop />,
      }}
    >
      {isAuthenticated ? (
        <PersonaCoinLink
          href={"/profile"}
          className="mx-lg laptop:hidden"
          size="32"
          displayName={profile?.displayName}
          imageUrl={"/api/profilePhoto/me"}
        />
      ) : (
        <>
          <SignInButton className="hidden laptop:flex" />
          <SignUpButton className="hidden laptop:flex" />
          <ButtonWithSigninDialogFallback
            buttonProps={{
              kind: "tertiary",
              label: "Sign in",
              className: "laptop:hidden",
            }}
          />
        </>
      )}
    </Header>
  );
};
