import Head from "next/head";
import React, { useEffect, useState } from "react";

import { DemoGrid } from "./DemoGrid";
import { EnvBadge } from "./EnvBadge";
import { Footer } from "./Footer";
import { HeaderMain } from "./HeaderMain";
import { UserContextProvider } from "./UserContext";
import { CookieConsentPopup } from "@/components/Cookie/CookieConsentPopup";
import {
  CookieConsent,
  getCookieConsent,
  saveCookieConsentAndRefreshCookieManagers,
} from "@/utils/cookies/cookies";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { Liability } from "@/server/backend-api/liability";
import { RouteChangeProgressBar } from "@/components/RouteChangeProgressBar";
import { RecaptchaScript } from "@/components/RecaptchaScript";

type Props = {
  children?: React.ReactNode;
  transparentHeader?: boolean;
  hideFooter?: boolean;
  termsAndConditions: Liability | null;
};

const Layout = (props: Props) => {
  /*
   * hasConsentInCookie
   * If a cookie consent has been saved in a cookie before this page load. Will
   * be false in server side rendering, but flipped to true in useEffect on first
   * client side render if cookie is present.
   *
   * haveUpdatedCookieConsentThisPageLoad
   * Whether the user has updated cookie consent on this page load, as setting
   * a new cookie does not trigger a component rerender.
   */
  const [
    haveUpdatedCookieConsentThisPageLoad,
    setHaveUpdatedCookieConsentThisPageLoad,
  ] = useState(false);
  const [hasParsedCookies, setHasParsedCookies] = useState(false);
  const [hasConsentInCookie, setHasConsentInCookie] = useState(false);

  /**
   * Parse cookies on component render (will only happen on browser, never on server-side render).
   */
  useEffect(() => {
    const savedCookieConsent = getCookieConsent();
    setHasConsentInCookie(savedCookieConsent.haveMadeChoice);
    setHasParsedCookies(true);
  }, []);

  function onCookieConsentSaved(consent: CookieConsent) {
    saveCookieConsentAndRefreshCookieManagers(consent, appInsightsReactPlugin);
    setHaveUpdatedCookieConsentThisPageLoad(true);
  }

  const appInsightsReactPlugin = useAppInsightsContext();
  const showCookiePopup =
    hasParsedCookies &&
    !hasConsentInCookie &&
    !haveUpdatedCookieConsentThisPageLoad;
  return (
    <>
      <Head>
        <title>ECIU</title>
        <link rel="icon" href="/favicon.png" />

        {/* Adding "noindex, nofollow" if build variable allowing indexing is missing  */}
        {process.env.NEXT_PUBLIC_DXP_ALLOW_SEARCH_ENGINE_INDEXING !==
          "true" && <meta name="robots" content="noindex, nofollow" />}
      </Head>
      <UserContextProvider>
        <RecaptchaScript />
        <RouteChangeProgressBar />
        {showCookiePopup && (
          <CookieConsentPopup
            showPopup={showCookiePopup}
            onCookieConsentSaved={onCookieConsentSaved}
            termsAndConditions={props.termsAndConditions}
          />
        )}
        <HeaderMain transparentHeader={props.transparentHeader} />
        {props.children}
        {!props.hideFooter && (
          <Footer
            onCookieConsentSaved={onCookieConsentSaved}
            termsAndConditions={props.termsAndConditions}
          />
        )}

        
      </UserContextProvider>

      {process.env.NEXT_PUBLIC_DXP_INCLUDE_DEV_GRID === "true" && <DemoGrid />}
      {process.env.NEXT_PUBLIC_DXP_ENV_BADGE && (
        <EnvBadge env={process.env.NEXT_PUBLIC_DXP_ENV_BADGE} />
      )}
    </>
  );
};

export default Layout;
