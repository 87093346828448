import { HeadlessUiMenuLink } from "@/components/Header/BrowseMenu";
import { mcn } from "@/utils/mergeClassNames";
import Link from "next/link";
import { useRouter } from "next/router";

type Props = {
  href: string;
  label: string;
  mobile?: boolean;
};
export const UserMenuItem = (props: Props) => {
  const router = useRouter();
  const current = router.asPath === props.href;

  const className = mcn(
    "block py-sm text-white text-style-body2",
    props.mobile ? "border-l-4 mr-2xs px-[40px]" : "border-r-4 ml-2xs px-lg",
    current ? "border-yellow-100" : "border-[transparent]",

    // focus
    "focus-visible:default-focus",
    current
      ? "focus-visible:border-yellow-100"
      : "focus-visible:border-yellow-100/60",

    // hover
    "hover:bg-light-yellow/5",
    current ? "hover:border-yellow-100" : "hover:border-yellow-100/60"
  );

  return props.mobile ? (
    <HeadlessUiMenuLink
      aria-label={`Link to ${props.label} page`}
      href={props.href}
      className={className}
    >
      {props.label}
    </HeadlessUiMenuLink>
  ) : (
    <Link
      href={props.href}
      aria-label={`Link to ${props.label} page`}
      className={className}
    >
      {props.label}
    </Link>
  );
};
