import { useState } from "react";
import Button from "@/components/Button";
import { CookieConsent } from "@/utils/cookies/cookies";
import { CustomizeCookiesDialog } from "@/components/Cookie/CustomizeCookiesDialog";
import { Liability } from "@/server/backend-api/liability";

interface Props {
  showPopup: boolean;
  onCookieConsentSaved: (consent: CookieConsent) => void;
  termsAndConditions: Liability | null;
}

export function CookieConsentPopup(props: Props) {
  const [showCookieCustomizeDialog, setShowCookieCustomizeDialog] =
    useState(false);

  const onlyNecessary: CookieConsent = {
    haveMadeChoice: true,
    haveConsentForWebAnalytics: false,
  } as const;

  const acceptAll: CookieConsent = {
    haveMadeChoice: true,
    haveConsentForWebAnalytics: true,
  } as const;

  if (!props.showPopup) {
    return null;
  }

  return (
    <>
      <CustomizeCookiesDialog
        open={showCookieCustomizeDialog}
        onClose={() => setShowCookieCustomizeDialog(false)}
        onCookieConsentSaved={props.onCookieConsentSaved}
        termsAndConditions={props.termsAndConditions}
      />
      <div className="fixed bottom-sm flex w-full justify-center z-50">
        <div className="bg-white w-full mx-sm tablet:h-[160px] tablet:w-[850px] rounded-2 shadow-elevation-3 ">
          <div className="m-lg flex flex-col justify-between gap-md tablet-sm:flex-row">
            <div>
              <h3 className="text-style-headline5">Own your cookies</h3>
              <p className="text-style-body2 mt-md">
                We use cookies on our website to offer you a better experience.
              </p>
              <button
                className="text-style-link1 mt-2xs"
                onClick={() => setShowCookieCustomizeDialog(true)}
              >
                Manage cookie settings
              </button>
            </div>
            <div className="tablet-sm:w-[200px] flex-shrink-0">
              <div className="flex flex-col gap-y-sm">
                <Button
                  label="Accept all"
                  onClick={() => {
                    props.onCookieConsentSaved(acceptAll);
                  }}
                />
                <Button
                  kind="link"
                  label="Only necessary"
                  onClick={() => {
                    props.onCookieConsentSaved(onlyNecessary);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
