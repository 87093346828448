import { SignOutButton } from "@/components/AuthButtons";
import { Persona } from "@/components/Persona";
import { mcn } from "@/utils/mergeClassNames";
import { Menu } from "@headlessui/react";
import { ProfileData } from "../../types";
import { UserMenuItem } from "./UserMenuItem";

const userMenuItems = [
  { label: "Home", href: "/" },
  { label: "Competence Passport", href: "/passport" },
];

export const UserMenuContentMobile = () => {
  return (
    <>
      <ul>
        {userMenuItems.map((item, index) => (
          <li key={index}>
            <UserMenuItem href={item.href} label={item.label} mobile={true} />
          </li>
        ))}
      </ul>
      <Menu.Item>
        {({ active }) => (
          <SignOutButton
            className={mcn(
              "mx-md mt-sm rounded-3 h-[40px]",
              active && "default-focus"
            )}
          />
        )}
      </Menu.Item>
      <div className="mx-md my-xl border-b border-white " />
    </>
  );
};

type Props = {
  profile: ProfileData | null;
};
export const UserMenuContentDesktop = (props: Props) => {
  return (
    <>
      <div>
        <ul>
          {userMenuItems.map((item, index) => (
            <li key={index}>
              <UserMenuItem href={item.href} label={item.label} />
            </li>
          ))}
        </ul>
      </div>
      <div className="mt-auto p-lg space-y-lg">
        <Persona
          displayName={props.profile?.displayName}
          imageUrl={"/api/profilePhoto/me"}
          href={"/profile"}
        />
        <SignOutButton />
      </div>
    </>
  );
};
