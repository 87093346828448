import { SVGProps } from "react";

const SvgIconActionEnvelope = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.006 0C31.0471 0 40.012 8.9529 40.012 20.006C40.012 31.0471 31.0591 40.012 20.006 40.012C8.9649 40.012 0 31.0591 0 20.006C0 8.9649 8.9529 0 20.006 0ZM22.2382 13.4053H25.9706V8.92889H22.2382C19.3579 8.92889 17.0177 11.2691 17.0177 14.1494V16.3816H14.0294V20.8581H17.0177V32.7873H21.4941V20.8581H25.2265L25.9706 16.3816H21.4941V14.1494C21.4941 13.7414 21.8302 13.4053 22.2382 13.4053Z"
      fill="#002337"
    />
  </svg>
);

export default SvgIconActionEnvelope;
