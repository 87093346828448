import { IconActionSignOut } from "@/components/Icons";
import { mcn } from "@/utils/mergeClassNames";
import config from "../config";
import Button from "./Button";

type Props = {
  className?: string;
};
export const SignOutButton = (props: Props) => (
  <a
    href="/signout"
    className={mcn(
      "text-style-body2 text-white bg-white/10 rounded-2",
      "hover:bg-white/20",
      "focus-visible:default-focus",
      "gap-2xs px-xs py-2xs",
      "flex items-center justify-center ",
      props.className
    )}
  >
    <IconActionSignOut fontSize={24} />
    Sign out
  </a>
);

export const SignInButton = (props: Props) => (
  <Button
    kind="tertiary"
    href={"/signin"}
    prefetch={false}
    className={props.className}
  >
    Sign in
  </Button>
);

export const SignUpButton = (props: Props) => (
  <Button
    kind="primary"
    href={config.auth.signUpUri}
    prefetch={false}
    className={props.className}
  >
    Sign up
  </Button>
);
