import { ProfileData } from "../../types";
import { BrowseMenuLearningOpportunities } from "./BrowseMenuLearningOpportunities";
import { BrowseMenuTopicCategories } from "./BrowseMenuTopicCategories";
import { UserMenuContentMobile } from "./UserMenuContent";

export const BrowseMenuContentDesktop = () => (
  <div className="grid grid-cols-2 gap-x-lg gap-y-md text-style-headline5 text-light-yellow">
    <h1>Learning opportunities</h1>
    <BrowseMenuLearningOpportunities />
    <BrowseMenuTopicCategories />
  </div>
);

export const BrowseMenuContentMobile = (props: {
  profile: ProfileData | null;
}) => (
  <>
    {props.profile && <UserMenuContentMobile />}
    <div className="px-md">
      <h2 className="text-style-overline text-white pl-lg mb-md">
        Browse learning opportunities
      </h2>
      <div className="flex flex-col gap-y-xs">
        <BrowseMenuLearningOpportunities />
      </div>
      <h2 className="text-style-overline text-white pl-lg mb-xs mt-xl">
        Browse topics
      </h2>
      <BrowseMenuTopicCategories mobile />
    </div>
  </>
);
