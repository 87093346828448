import Checkbox from "@/components/Input/Checkbox";
import Button from "@/components/Button";
import { Dialog } from "@/components/Modal/Dialog";
import { CookieConsent } from "@/utils/cookies/cookies";
import { useState } from "react";
import Link from "../Link";
import { Liability } from "@/server/backend-api/liability";

interface Props {
  open: boolean;
  onClose: () => void;
  onCookieConsentSaved: (consent: CookieConsent) => void;
  termsAndConditions: Liability | null;
}

export function CustomizeCookiesDialog(props: Props) {
  const [isAnalyticsChecked, setIsAnalyticsChecked] = useState(false);
  return (
    <Dialog
      open={props.open}
      title="Manage cookie settings"
      onClose={props.onClose}
    >
      <div className="flex flex-col gap-sm mt-lg">
        <h5 className="text-style-headline5">Own your cookies</h5>
        <p className="text-style-body2 mb-lg">
          Please be aware that choosing not to enable certain types of cookies
          may impact your experience of the website and the availability of some
          services. Read more in our{" "}
          <Link
            href={props.termsAndConditions?.url ?? "/404"}
            className="text-style-link1"
          >
            terms and conditions
          </Link>
        </p>
        <Checkbox
          id="cookie-necessary"
          checked={true}
          disabled={true}
          labelProps={{
            text: "Cookies necessary for the website to function",
          }}
        />
        <Checkbox
          id="cookie-analytics"
          checked={isAnalyticsChecked}
          onChange={() => {
            setIsAnalyticsChecked(!isAnalyticsChecked);
          }}
          labelProps={{
            text: "Web browser analytics and telemetry",
          }}
        />
        <div className="flex justify-end">
          <Button
            kind="primary"
            label="Save"
            onClick={() => {
              props.onCookieConsentSaved({
                haveMadeChoice: true,
                haveConsentForWebAnalytics: isAnalyticsChecked,
              });
            }}
          />
        </div>
      </div>
    </Dialog>
  );
}
