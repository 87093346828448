import { Dialog } from "@/components/Modal/Dialog";
import { useState } from "react";
import SpinnerOverlay from "@/components/SpinnerOverlay";
import { useSystemMessages } from "@/hooks/useSystemMessages";
import {
  ContactUsForm,
  ContactUsFormProps,
} from "@/components/Contact/ContactUsForm";
import { OrganisationBase } from "@/server/backend-api/organisation";

export interface ContactUsDialogProps {
  show: boolean;
  onClose: () => void;
  formProps?: Pick<ContactUsFormProps, "presets" | "title" | "description">;
  universities: OrganisationBase[];
  supportSettings: ContactUsFormProps["supportSettings"];
  learningOppNames: ContactUsFormProps["learningOppNames"];
  profile?: ContactUsFormProps["profile"];
}

export function ContactUsDialog(props: ContactUsDialogProps) {
  const [isLoading, setIsLoading] = useState(false);
  const { FixedSystemMessageList, addSystemMessage } = useSystemMessages();
  const TIME_SHOW_MESSAGES_SECONDS = 8;
  return (
    <>
      <FixedSystemMessageList />
      {isLoading && (
        <SpinnerOverlay
          isTransparent
          loadingText="Sending support request..."
        />
      )}
      <Dialog
        open={props.show}
        onClose={() => {
          props.onClose();
        }}
        disableCloseByClickingOutside={true}
      >
        <ContactUsForm
          {...props.formProps}
          universities={props.universities}
          supportSettings={props.supportSettings}
          learningOppNames={props.learningOppNames}
          // Override padding here.
          formClassNames="p-0"
          profile={props.profile}
          onSendStateChanged={(newState) => {
            switch (newState) {
              case "loading":
                // Signal to parent that dialog should be closed by this stage.
                props.onClose();
                setIsLoading(true);
                break;
              case "error":
                setIsLoading(false);
                addSystemMessage({
                  props: {
                    kind: "error",
                  },
                  text: "An error has occurred. Try again later or contact helpdesk@eciu.eu",
                  dismissAfterSeconds: TIME_SHOW_MESSAGES_SECONDS,
                });
                break;
              case "success":
                setIsLoading(false);
                addSystemMessage({
                  text: `Your request for support has been received. We will get back to you as soon as possible.`,
                  props: {
                    kind: "success",
                  },
                  dismissAfterSeconds: TIME_SHOW_MESSAGES_SECONDS,
                });
                break;
            }
          }}
        />
      </Dialog>
    </>
  );
}
