import { mcn } from "@/utils/mergeClassNames";
import { useCurrentScreenSize } from "@/utils/useCurrentScreenSize";
import { useState } from "react";
import { screens } from "../tailwind/config";

const GridColumns = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12] as const;
type GridNumber = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

export const DemoGrid = () => {
  const screen = useCurrentScreenSize();
  const [show, setShow] = useState(false);
  const [containerHeight, setContainerHeight] = useState(0);
  const [rows, setRows] = useState<8 | 16>();
  const [selectedColumns, setSelectedColumns] = useState<GridNumber[]>([
    ...GridColumns,
  ]);

  const toggleColumns = () => {
    if (selectedColumns.length === 0) setSelectedColumns([...GridColumns]);
    else if (selectedColumns.length === 12) setSelectedColumns([1, 12]);
    else setSelectedColumns([]);
  };

  const toggleColumn = (columnNumber: GridNumber) => {
    if (selectedColumns.includes(columnNumber)) {
      setSelectedColumns(selectedColumns.filter((x) => x !== columnNumber));
    } else {
      setSelectedColumns([...selectedColumns, columnNumber]);
    }
    selectedColumns;
  };

  const toggleRows = () => {
    setContainerHeight(document.body.offsetHeight);
    if (rows === 8) setRows(16);
    else if (rows === 16) setRows(undefined);
    else setRows(8);
  };

  const firstColSelected = selectedColumns.includes(1);
  const lastColSelected = selectedColumns.includes(12);

  const anyOtherColSelected =
    selectedColumns.filter((x) => x !== 1 && x !== 12).length > 0;

  const rowHeight = rows;

  return (
    <>
      <div className="fixed left-0 bottom-0 z-50 leading-none">
        <div className="flex flex-col">
          {show && (
            <>
              <div className="flex flex-row p-2 space-x-2">
                <button
                  className={mcn(
                    "flex flex-row w-4 h-4 place-content-between",
                    selectedColumns.length > 0
                      ? "text-red-100"
                      : "text-text-secondary"
                  )}
                  onClick={toggleColumns}
                >
                  <div
                    className={mcn(
                      "h-full border-r-2",
                      firstColSelected ? "text-red-100" : "text-text-secondary"
                    )}
                  />
                  <div
                    className={mcn(
                      "h-full border-r-2",
                      anyOtherColSelected
                        ? "text-red-100"
                        : "text-text-secondary"
                    )}
                  />
                  <div
                    className={mcn(
                      "h-full border-r-2",
                      lastColSelected ? "text-red-100" : "text-text-secondary"
                    )}
                  />
                </button>
                <button
                  className={mcn(
                    "flex flex-col w-4 h-4 place-content-between",
                    rows ? "text-red-100" : "text-text-secondary"
                  )}
                  onClick={toggleRows}
                >
                  <div className="w-full border-b-2" />
                  <div className="w-full border-b-2" />
                  <div className="w-full border-b-2" />
                </button>
              </div>
            </>
          )}
          <button
            aria-label="Show dev grid"
            className={mcn(
              "p-1 w-16 h-12 text-left text-white",
              show && "bg-text-secondary hover:bg-text-primary"
            )}
            onClick={() => setShow(!show)}
          >
            {show && (
              <>
                {screen} <br /> {screen ? screens[screen] : "0px"}
              </>
            )}
          </button>
        </div>
      </div>

      {rowHeight && show && (
        <div
          className="absolute top-0 left-0 w-full  "
          style={{ height: `${containerHeight}px` }}
        >
          <div className="container  h-full ">
            <div
              className={mcn("text-center  h-full")}
              style={{
                background: `repeating-linear-gradient(
                  #ff000040, #ff000040 ${rowHeight}px, 
                  transparent ${rowHeight}px, transparent ${rowHeight * 2}px
                )`,
              }}
            />
          </div>
        </div>
      )}
      {show && (
        <div className="fixed w-full bottom-0">
          <div className="container grid grid-cols-12 gap-default">
            {GridColumns.map((columnNumber) => (
              <div
                key={columnNumber}
                className="col-span-1 flex items-center justify-center relative"
              >
                {selectedColumns.includes(columnNumber) && (
                  <div
                    className={mcn(
                      "absolute bottom-0 left-0 w-full h-screen border-x",
                      "border-x-red-100/50 bg-grey-20/10"
                    )}
                  />
                )}
                <button
                  className="w-full h-8 z-50 font-bold bg-grey-20"
                  onClick={() => toggleColumn(columnNumber)}
                >
                  {columnNumber}
                </button>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};
