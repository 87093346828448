import { SVGProps } from "react";

const IconAddImage = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="40" height="40" rx="20" fill="#FCEDC6" />
    <path
      d="M17.0723 8.75C16.3232 8.75 15.6382 9.15698 15.3018 9.80182L14.5231 11.2947H12.2912C10.4673 11.2947 8.98865 12.7192 8.98865 14.4765V24.6591C8.98865 26.4163 10.4673 27.8409 12.2912 27.8409H19.0219C18.8079 27.4377 18.6334 27.0119 18.5031 26.5682H12.2912C11.1968 26.5682 10.3097 25.7134 10.3097 24.6591V14.4765C10.3097 13.4221 11.1968 12.5674 12.2912 12.5674H14.9303C15.18 12.5674 15.4084 12.4318 15.5205 12.2168L16.4821 10.3733C16.5943 10.1584 16.8226 10.0227 17.0723 10.0227H22.045C22.2952 10.0227 22.5238 10.1589 22.6357 10.3745L23.591 12.2156C23.7028 12.4312 23.9315 12.5674 24.1817 12.5674H26.8224C27.9169 12.5674 28.804 13.4221 28.804 14.4765V18.4254C29.2768 18.6588 29.7195 18.9406 30.125 19.264V14.4765C30.125 12.7192 28.6465 11.2947 26.8224 11.2947H24.59L23.8174 9.80547C23.4817 9.15862 22.7956 8.75 22.045 8.75H17.0723Z"
      fill="black"
    />
    <path
      d="M19.633 13.5225C22.0672 13.5225 24.1098 15.1983 24.6705 17.4592C24.2331 17.508 23.8077 17.5964 23.3978 17.7207C22.9594 16.0513 21.44 14.8199 19.633 14.8199C17.4833 14.8199 15.7406 16.5626 15.7406 18.7122C15.7406 20.5192 16.972 22.0387 18.6414 22.4771C18.5172 22.8869 18.4288 23.3124 18.38 23.7497C16.1191 23.1892 14.4432 21.1464 14.4432 18.7122C14.4432 15.846 16.7667 13.5225 19.633 13.5225Z"
      fill="black"
    />
    <path
      d="M31.4887 24.773C31.4887 27.9738 28.7413 30.5684 25.3523 30.5684C21.9633 30.5684 19.2159 27.9738 19.2159 24.773C19.2159 21.5722 21.9633 18.9775 25.3523 18.9775C28.7413 18.9775 31.4887 21.5722 31.4887 24.773ZM26.0341 22.1972C26.0341 21.8417 25.7289 21.5533 25.3523 21.5533C24.9758 21.5533 24.6704 21.8417 24.6704 22.1972V24.1291H22.625C22.2485 24.1291 21.9432 24.4174 21.9432 24.773C21.9432 25.1286 22.2485 25.4169 22.625 25.4169H24.6704V27.3488C24.6704 27.7043 24.9758 27.9927 25.3523 27.9927C25.7289 27.9927 26.0341 27.7043 26.0341 27.3488V25.4169H28.0795C28.4562 25.4169 28.7614 25.1286 28.7614 24.773C28.7614 24.4174 28.4562 24.1291 28.0795 24.1291H26.0341V22.1972Z"
      fill="black"
    />
  </svg>
);

export default IconAddImage;
