import { SVGProps } from "react";

const SvgIconActionEnvelope = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.006 0C31.0471 0 40.012 8.9529 40.012 20.006C40.012 31.0471 31.0591 40.012 20.006 40.012C8.9649 40.012 0 31.0591 0 20.006C0 8.9649 8.9529 0 20.006 0ZM17.4257 16.0336H21.5302V18.1338H21.5902C22.1662 17.1017 23.5584 16.0336 25.6466 16.0336C29.979 16.0336 30.7831 18.7339 30.7831 22.2382V29.3909H26.4986V23.0543C26.4986 21.5422 26.4626 19.598 24.2664 19.598C22.0342 19.598 21.6982 21.2421 21.6982 22.9463V29.3909H17.4137V16.0336H17.4257ZM14.4614 12.3252C14.4614 13.5494 13.4653 14.5575 12.2292 14.5575C11.0051 14.5575 9.997 13.5614 9.997 12.3252C9.997 11.1011 10.9931 10.093 12.2292 10.093C13.4533 10.093 14.4614 11.0891 14.4614 12.3252ZM10.009 16.0336H14.4614V29.3909H10.009V16.0336Z"
      fill="#002337"
    />
  </svg>
);

export default SvgIconActionEnvelope;
