import { mcn } from "@/utils/mergeClassNames";
import { useState } from "react";

type Props = {
  env: string;
};
export const EnvBadge = (props: Props) => {
  const [hidden, setHidden] = useState(false);
  if (!props.env || hidden) return null;

  const text = `${props.env} environment.
    
This version of the platform/website is for testing purposes only.`;

  return (
    <div
      className={mcn(
        "fixed left-0 right-0 mx-auto top-0 z-50",
        "flex items-center justify-center"
      )}
    >
      <button
        title={text}
        className={mcn(
          "w-24 py-2xs rounded-b-3",
          "text-blue",
          props.env === "DEV" ? "bg-yellow-100" : "bg-red-100 "
        )}
        onClick={() => {
          if (confirm(`${text}\n\nHide badge?`)) setHidden(true);
        }}
      >
        <div className={mcn("text-style-subtitle2 text-center")}>
          {props.env}
        </div>
      </button>
    </div>
  );
};
