import { PersonaCoin } from "./PersonaCoin";
import { PersonaCoinLink } from "@/components/PersonaCoinLink";

type Props = {
  displayName: string | undefined | null;
  imageUrl: string | undefined | null;
  href?: string;
};
export const Persona = (props: Props) => {
  const { displayName, href } = props;

  return (
    <div className="flex flex-col items-center space-y-2">
      {href ? (
        <PersonaCoinLink {...props} href={href} />
      ) : (
        <PersonaCoin {...props} />
      )}
      <div className="text-white text-center text-style-subtitle1">
        {displayName}
      </div>
    </div>
  );
};
