import { BaseContextProvider } from "@/components/BaseContext";
import { isBackendClientAllowed } from "@/server/backend-api/createBackendApiClient";
import {
  getMemberUniversities,
  OrganisationBase,
} from "@/server/backend-api/organisation";
import {
  initClientSideAppInsights,
  trackWebVitals,
} from "@/utils/appInsights/client";
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import type {
  AppContext,
  AppInitialProps,
  AppProps,
  NextWebVitalsMetric,
} from "next/app";
import App from "next/app";
import { QueryClient, QueryClientProvider } from "react-query";
import { NextPageWithLayout } from "types";
import Layout from "../components/layout";
import "../styles/globals.css";
import { getLiability, Liability } from "@/server/backend-api/liability";
import { AuthStatus, getAuthStatus } from "@/server/getAuthStatus";
import { useEffect } from "react";
import {
  CompetenceBaseTreeEx,
  getCompetenceTree,
  getLearningOpportunities,
  LearningOpportunityName,
} from "@/server/backend-api";
import { DUMMY_TREE } from "@/utils/competenceTree";
import { ContactFormSetting } from "@/server/backend-api/generated";
import { getContactFormSettings } from "@/server/backend-api/support";

const { reactPlugin, appInsights } = initClientSideAppInsights(
  process.env.NEXT_PUBLIC_DXP_APP_INSIGHTS_CLIENT_CONNECTION_STRING
);

export function reportWebVitals(metric: NextWebVitalsMetric) {
  trackWebVitals(appInsights, metric);
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: { refetchOnWindowFocus: false, retry: false },
  },
});

function MyApp(
  props: AppProps & {
    Component: NextPageWithLayout;
  } & ExtraInitialProps
) {
  const { Component, pageProps } = props;
  useEffect(() => {
    const status = props.authStatus;
    if (appInsights === undefined) {
      return;
    }
    if (status.isAuthenticated && status.userId) {
      appInsights.setAuthenticatedUserContext(status.userId, undefined, true);
    }
  },);

  return (
    <AppInsightsContext.Provider value={reactPlugin}>
      <BaseContextProvider
        competenceTree={props.competenceTree}
        memberUniversities={props.memberUniversities}
        contactFormSettings={props.contactFormSettings}
        learningOpps={props.learningOppNames}
      >
        <QueryClientProvider client={queryClient}>
          <Layout
            termsAndConditions={props.termsAndConditions}
            transparentHeader={Component.layout?.transparentHeader}
            hideFooter={Component.layout?.hideFooter}
          >
            <Component {...pageProps} />
          </Layout>
        </QueryClientProvider>
      </BaseContextProvider>
    </AppInsightsContext.Provider>
  );
}

interface ExtraInitialProps {
  competenceTree: CompetenceBaseTreeEx;
  memberUniversities: OrganisationBase[];
  termsAndConditions: Liability | null;
  authStatus: AuthStatus;
  contactFormSettings: ContactFormSetting[];
  learningOppNames: LearningOpportunityName[];
}

/* 
  getInitialProps together with BaseContextProvider its used to give 
  easy access to topic and memberUniversitiy lists all the time.

  Note that getInitialProps are called per request most of the time 
  (if the page are SSR), but for pages with getStaticProps its only 
  called at build time (and on revalidate).

  We return empty list when we aren't allowed to use backend api
  (!isBackendClientAllowed). This happends during build in Azure devops
  pipeline. This means that first version of static pages will have empty
  topics and must be revalidated directly after deploy (with /api/revalidate-ssg)
*/
MyApp.getInitialProps = async (
  appContext: AppContext
): Promise<AppInitialProps & ExtraInitialProps> => {
  const appProps = await App.getInitialProps(appContext);
  const req = appContext.ctx.req;
  let authStatus: AuthStatus = {
    isAuthenticated: false,
    token: undefined,
    userId: undefined,
    userName: undefined,
  };

  if (req) {
    authStatus = getAuthStatus(req);
  }
  


  const competenceTreePromise = !isBackendClientAllowed
    ? Promise.resolve(DUMMY_TREE)
    : getCompetenceTree();
  const memberUniversitiesPromise = !isBackendClientAllowed
    ? Promise.resolve([])
    : getMemberUniversities();
  const termsAndConditionsPromise = isBackendClientAllowed
    ? getLiability("CreateAccount")
    : Promise.resolve(null);
  const contactFormSettingsPromise = isBackendClientAllowed
    ? getContactFormSettings()
    : Promise.resolve([]);
  const learningOppNamesPromise = isBackendClientAllowed
    ? getLearningOpportunities(req)
    : Promise.resolve([]);

  const learningOppNames: LearningOpportunityName[] = (
    await learningOppNamesPromise
  )
    .map((x) => ({
      id: x.id,
      studyPeriod: x.studyPeriod ?? "",
      studyPeriodShort: x.studyPeriodShort ?? "",
      name: x.title ?? "",
    }))
    .sort((a, b) => a.name.localeCompare(b.name));
  return {
    ...appProps,
    competenceTree: await competenceTreePromise,
    memberUniversities: await memberUniversitiesPromise,
    termsAndConditions: await termsAndConditionsPromise,
    authStatus,
    contactFormSettings: await contactFormSettingsPromise,
    learningOppNames: learningOppNames,
  };
};

export default MyApp;
